import HomePage from '@components/HomePage';
import config, { AppLanguage } from '@config';
import { useAppActionDispatch } from '@redux/hooks';
import { setAppLanguage } from '@redux/layoutSlice';
import { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { FunctionComponent } from 'react';

interface HomePageForLanguageParams extends ParsedUrlQuery
{
    language: string;
}

interface HomePageForLanguageProps
{
    language: AppLanguage | null;
}

export const getStaticPaths: GetStaticPaths<HomePageForLanguageParams> = async () =>
{
    let paths: Array<{ params: HomePageForLanguageParams }> = [];

    for (let language of config.languages)
    {
        let path: {params: HomePageForLanguageParams} =  {
            params:
            {
                language: language.code
            }
        }
        paths.push(path);
    }

    return {
        paths,
        fallback: 'blocking',
    };
}

export const getStaticProps: GetStaticProps<HomePageForLanguageProps, HomePageForLanguageParams> = async (context) => 
{
    let language = config.languages.find(l => l.code == context.params?.language);
    
    return {
        props: {
           language: language != null ? language : null
        },
    };
}

const HomePageForLanguage: FunctionComponent<HomePageForLanguageProps> = (props) =>
{
    const dispatch = useAppActionDispatch();

    /** If the language is not available: set the language to the default language: */
    let isLanguageAvailable = config.languages.some(l => l.code == props.language?.code);
    let language = isLanguageAvailable ? props.language : config.defaultLanguage;

    /** Set the language with Redux (to be globally accessible) */
    dispatch(setAppLanguage(language!));

    /** Render */
    return (
        <>
            <HomePage />
        </>
    );
}

export default HomePageForLanguage;